import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.microsoft.com/en-us/dotnet/csharp/fundamentals/coding-style/coding-conventions"
        }}>{`C# Coding Conventions (Microsoft)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://csharpcodingguidelines.com"
        }}>{`C# Coding Guidelines (Aviva Solutions)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.sourceformat.com/pdf/cs-coding-standard-idesign.pdf"
        }}>{`C# Coding Standard 2.0 (IDesign)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dzone.com/articles/c-best-practices"
        }}>{`C# Best Practices (DZone)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      